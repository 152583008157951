import { useEffect } from "react";
import { Menu, MenuProps } from "antd";
import { useLocation } from "react-router-dom";
import { useMercuryContext } from "../../user-context";
import { Link } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";

interface LeftMenuProps {
  adminMenuData: {
    handleMainMenuSelection: (key: string | null) => void;
    currentMenuItem: string;
  };
  mobile?: boolean;
}

const LeftMenu: React.FC<LeftMenuProps> = ({
  adminMenuData,
  mobile = false,
}) => {
  const { isAdmin, userDetails, flags } = useMercuryContext();
  const location = useLocation();

  const items: MenuProps["items"] = [
    flags?.searchTab && {
      label: (
        <Link
          to={"/"}
          onClick={() => adminMenuData.handleMainMenuSelection("projects")}
        >
          Search {!mobile && <DownOutlined />}
        </Link>
      ),
      key: "search",
      popupClassName: "search-dropdown-menu",
      children: [
        {
          label: <Link to={"/"}>Projects</Link>,
          key: "projects",
        },
        {
          label: <Link to={"/plps"}>Plps</Link>,
          key: "plps",
        },
        {
          label: <Link to={"/documents"}>Documents</Link>,
          key: "documents",
        },
      ],
    },
    {
      label: <Link to={"/invoicing"}>Invoicing</Link>,
      key: "invoicing",
      // icon: <DownOutlined />,
    },
    (userDetails.vendorManager || isAdmin) && {
      label: <Link to={"/linguist-management"}>Linguist Management</Link>,
      key: "linguist-management",
    },
    {
      label: <Link to={"/reports"}>Reports</Link>,
      key: "reports",
    },
    {
      label: <Link to={"/extension-requests"}>Extension Requests</Link>,
      key: "extension-requests",
    },
    (flags?.adjustmentsTab || isAdmin) && {
      label: <Link to={"/adjustments"}>Adjustments</Link>,
      key: "adjustments",
    },
  ];

  const pathToKeyMap = {
    "/": "projects",
    "/project/:id": "projects",
    "/plps": "plps",
    "/plps/:id": "plps",
    "/documents": "documents",
    "/document/:id": "documents",
    "/invoicing": "invoicing",
    "/invoicing-project/:id": "invoicing",
    "/linguist-management": "linguist-management",
    "/reports": "reports",
    "/reports/my-reports": "reports",
    "/extension-requests": "extension-requests",
    "/adjustments": "adjustments",
    "/adjustments/:id": "adjustments",
  };

  const getActiveKey = () => {
    const currentPath = location.pathname;

    for (const [path, key] of Object.entries(pathToKeyMap)) {
      const dynamicPathRegex = new RegExp(
        path.replace(/:[^/]+/g, "[^/]+") + "$"
      );
      if (dynamicPathRegex.test(currentPath)) {
        return key;
      }
    }

    return "";
  };

  const activeKey = getActiveKey();

  useEffect(() => {
    const allItems = items
      ?.flatMap((item) => {
        if (item && "children" in item && Array.isArray(item.children)) {
          return [...item.children, item];
        }
        return item;
      })
      .filter(Boolean);

    const path =
      location?.pathname === "/" || location.pathname.includes("/project/")
        ? "search"
        : location?.pathname.replaceAll("/", "");

    const isUsed = allItems?.filter((i) =>
      path.includes(i?.key as string)
    ).length;

    if (!isUsed) {
      adminMenuData.handleMainMenuSelection(null);
    }
  }, [location, items, adminMenuData]);

  return (
    <Menu
      onClick={(e) => adminMenuData.handleMainMenuSelection(e.key as string)}
      selectedKeys={[activeKey]}
      mode={mobile ? "inline" : "horizontal"}
      items={items}
    />
  );
};

export default LeftMenu;
